import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, Zoom, Paper } from '@material-ui/core';

import SeoHelmet from 'components/SeoHelmet';
import Layout from 'components/Layout';
import BgVideo from 'components/BgVideo';

export const styles = ({ spacing, palette }) => ({
  flexWrap: {
    height: 'calc(100vh - 147px)',
  },
  paper: {
    opacity: 0.6,
    padding: `2px 0`,
    backgroundColor: 'transparent',
  },
  introText: {
    backgroundColor: palette.primary.contrastText,
    marginBottom: spacing(2),
  },
  centerIntro: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: 'calc(100vh - 147px)',
    flex: 'auto',
  },
});

const Home = ({ classes }) => (
  <>
    <BgVideo>
      <Layout disabledContentWrap>
        <SeoHelmet title="Home" />
        <div>
          <div className={classes.centerIntro}>
            <Zoom in style={{ transitionDelay: '1000ms' }}>
              <Paper elevation={0} className={classes.paper} square>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  component="span"
                  className={classes.introText}>
                  &nbsp;Servicii de amenajare &nbsp;
                </Typography>
              </Paper>
            </Zoom>
            <Zoom in style={{ transitionDelay: '1500ms' }}>
              <Paper elevation={0} className={classes.paper} square>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  component="span"
                  className={classes.introText}>
                  &nbsp;pentru proprietati comerciale si rezidentiale,&nbsp;
                </Typography>
              </Paper>
            </Zoom>
            <Zoom in style={{ transitionDelay: '2000ms' }}>
              <Paper elevation={0} className={classes.paper} square>
                <Typography
                  variant="h1"
                  color="textPrimary"
                  component="span"
                  className={classes.introText}>
                  &nbsp;inclusiv medii corporative, parcuri&nbsp;
                </Typography>
              </Paper>
            </Zoom>
          </div>
        </div>
      </Layout>
    </BgVideo>
  </>
);
Home.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Home);
