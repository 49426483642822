import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { IconButton, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { BsFillVolumeDownFill, BsVolumeMute } from 'react-icons/bs';

import video from 'images/video.mp4';

const styles = ({ palette, spacing, breakpoints }) => ({
  bgVideoRoot: {
    width: '100%',
    height: '100vh',
    position: 'relative',
    '& video': {
      objectFit: 'cover',
    },
  },
  bgVideoWrap: {
    position: 'absolute',
    top: 56,
    height: 'calc(100vh - 56px)',
    [breakpoints.up('md')]: {
      top: 64,
      height: 'calc(100vh - 64px)',
    },
    width: '100%',
  },
  bgVideoControls: {
    position: 'absolute',
    right: 0,
    top: 60,
    zIndex: 99999,
    padding: spacing(2),
  },
  bgVideoBtn: {
    color: palette.common.white,
  },
  bgVideoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  title: {
    paddingBottom: spacing(4),
  },
});

function BgVideo({ classes, children }) {
  const playerRef = useRef(null);
  const [isMuted, setPlayerMuted] = useState(true);

  const toggleMute = () => {
    setPlayerMuted(!isMuted);
  };

  return (
    <section className={classes.bgVideoRoot}>
      <div className={classes.bgVideoWrap}>
        <ReactPlayer
          url={video}
          ref={playerRef}
          playing
          loop
          muted={isMuted}
          width="100%"
          height="100%"
        />
      </div>
      <Box className={classes.bgVideoOverlay}>{children}</Box>
      <div className={classes.bgVideoControls}>
        <IconButton onClick={toggleMute} className={classes.bgVideoBtn}>
          {!isMuted ? (
            <BsFillVolumeDownFill size={44} color="inherit" />
          ) : (
            <BsVolumeMute size={44} color="inherit" />
          )}
        </IconButton>
      </div>
    </section>
  );
}

BgVideo.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(BgVideo);
